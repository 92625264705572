import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useTheme } from "@mui/material";
import React from "react";

export default function CustomPagination() {
  const theme = useTheme();

  const customPaginationStyle = {
    ".MuiPaginationItem-root": {
      border: "none !important",
      borderRadius: "0 !important",
      color: theme.palette.primary.main,
      opacity: 1,
      "&.Mui-selected": {
        backgroundColor: "#FFFFFF",
        color: theme.palette.info.main,
        boxShadow: "0px 0px 10px rgba(162, 170, 180, 0.25) !important",
      },
    },
  };
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      sx={customPaginationStyle}
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
