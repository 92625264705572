import React from "react";
export const GraphicContext = React.createContext();

export const useGraphic = () => {
  const context = React.useContext(GraphicContext);
  if (!context) {
    throw new Error("useGraphic must be used within a GraphicProvider");
  }
  return context;
};

export const GraphicProvider = ({ children }) => {
  const [title, setTitle] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [config, setConfig] = React.useState({});
  const [icon, setIcon] = React.useState("");
  const [logo, setLogo] = React.useState({});

  const value = React.useMemo(
    () => ({
      title,
      setTitle,
      theme,
      setTheme,
      config,
      setConfig,
      icon,
      setIcon,
      logo,
      setLogo,
    }),
    [
      title,
      setTitle,
      theme,
      setTheme,
      config,
      setConfig,
      icon,
      setIcon,
      logo,
      setLogo,
    ]
  );
  return (
    <GraphicContext.Provider value={value}>{children}</GraphicContext.Provider>
  );
};
