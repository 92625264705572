import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import "./RaterForm.scss";
import { useNavigate } from "react-router-dom";
import { BasketContext } from "../../context";
import HumanAdvice from "../HumanAdvice/HumanAdvice";
import {
  evalScript,
  getApi,
  pauseEvent,
  resumeEvent,
} from "../../service/PageService";
import DynamicComponent from "../DynamicComponent/DynamicComponent";
import { Box, Button, CircularProgress, Icon, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import BackIcon from "../../../../assets/images/back.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  reloadBasket,
  resetBasket,
  saveBasket,
} from "../../service/BasketService";
import Text from "../../page-components/Text";
import { createQuote } from "../../service/QuoteService";
import { remoteAccessStore } from "../../store/remote-access";

const RaterForm = () => {
  const [loading, setLoading] = useState();
  const [canGoBack, setCanGoBack] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const firstTime = useRef(false);
  const evaluatingScript = useRef(false);
  const currentNav = useRef("");
  const [loadingNext, setLoadingNext] = useState(false);
  const [
    needExternalToken,
    redirectUrl,
    externalToken,
    extraConfig,
    currentAppBrand
  ] = remoteAccessStore((state) => [
    state.needExternalToken,
    state.redirectUrl,
    state.externalToken,
    state.extraConfig,
    state.currentAppBrand,
  ]);


  const {
    basket,
    changeFormState,
    setNavigationState,
    formState,
    product,
    goTo,
    navigationState,
    brand,
    getCurrentFormId,
    setAdvices,
    advices,
  } = useContext(BasketContext);

  const navigate = useNavigate();

  const getAdvice = useCallback(() => formState?.advice, [formState]);
  const setAdvice = useCallback((advice) => {
    changeFormState({ advice });
  }, [changeFormState]);

  const handleDelete = async () => {
    await resetBasket(basket);
    /*
        setCanDelete(false);
        await saveBasket(basket);
        setBasket(basket);*/
  };

  const refreshForm = useCallback(() => {
    console.log("%c>> Force refresh.\n", "background: #EFE; color: #050");
    changeFormState({});
  }, [changeFormState]);

  const handleNext = async () => {
    setLoadingNext(true);

    // Vérifie état valide
    let error = false,
      messages = [];

    for (let [key, elem] of formState.registry) {
      if (elem.hasOwnProperty("visible") && !elem.visible) {
        continue;
      }
      if (
        elem.hasOwnProperty("validates") &&
        typeof elem.validates === "function"
      ) {
        const res = await elem.validates();
        if (res && res.hasOwnProperty("error") && res.error) {
          if (!error) error = elem;
          /*if(Array.isArray(res.messages)) {
                        messages = [...messages, ...res.messages]
                    }*/
        }
      }
    }

    const state = {
      error: false,
      messages: [],
    };

    await triggerEvalScript(
      "validate",
      navigationState.asset,
      navigationState.formId,
      navigationState.itemId,
      {
        error: (msg) => {
          state.error = true;
          state.messages.push(msg);
        },
      }
    );

    if (state.error) {
      error = true;
      messages = [...state.messages];
      setFormMessage(messages.join("\n"));
    } else {
      setFormMessage("");
    }

    if (error) {
      if (error.hasOwnProperty("focus") && typeof error.focus === "function") {
        error.focus();
      }
      refreshForm();
      setLoadingNext(false);
      return;
    }

    setLoading(true);

    // Passe en attente
    const { asset, formId, itemId } = navigationState;
    const scope = asset ? basket.getItem(asset, itemId) : basket;

    for (let [key, elem] of formState.registry) {
      if (elem.hasOwnProperty("value")) {
        scope.set(key, elem.value);
      }

      if (elem.hasOwnProperty("checked")) {
        scope.set(key, elem.checked);
      }
    }

    const { nextForm } = formState;

    if (nextForm) {
      const form = product.forms.find((f) => f.id === nextForm.id);
      const assetDef =
        form && form.asset
          ? product.fleet.find((f) => f.asset.id === form.asset.id)?.asset
          : null;

      if (asset && form && !form.asset) {
        scope.goEnd();
      } else if (asset && assetDef && assetDef.code === asset) {
        scope.goTo(nextForm.id);
      } else if (!asset && !form.asset) {
        scope.goTo(nextForm.id);
      }
    }
    // Sauvegarde panier
    await saveBasket(basket);
    // Raffraîchit panier.
    await reloadBasket(basket);

    // Execute les postScripts
    let _hasChanged = false;
    basket.setCallback(() => (_hasChanged = true));
    await triggerEvalScript("after", asset, formId, itemId);

    if (_hasChanged) {
      // Sauvegarde panier
      await saveBasket(basket);
      // Raffraîchit panier.
      await reloadBasket(basket);
    }

    setCanDelete(true);

    // Va au suivant
    if (nextForm) {
      goTo(nextForm.id, -1);
    } else {
      basket.goEnd();
      await saveBasket(basket);

      if (product.subscriptionEnabled) {
        const quote = await createQuote(basket.id);
        navigate("/quote/" + quote._id);
      } else {
        goTo(-1, -1);
      }
    }
  };

  useEffect(() => {
    setCanGoBack(
      (() => {
        if (navigationState.asset) {
          const items = basket.getItems(navigationState.asset);
          if (!items[navigationState.itemId]) return false;
          if (items[navigationState.itemId].history.length > 1) return true;
          return items.length > 1;
        }
        return basket.history.length > 1;
      })()
    );
    if (!basket.isEmpty()) {
      setCanDelete(true);
    }
  }, [navigationState, basket]);

  const handlePrev = async () => {
    const { asset, itemId, gen } = navigationState;

    setLoading(true);

    if (asset) {
      const item = basket.getItem(asset, itemId);
      item.goBack();
      const backPage = item.currentPage();
      if (backPage > -1) {
        setNavigationState({
          gen: gen + 1,
          formId: backPage,
          asset,
          itemId,
        });
      } else {
        if (!item.isComplete()) {
          basket.removeItem(asset, itemId);
        }
        setNavigationState({
          gen: gen + 1,
          formId: basket.currentPage(),
          asset: null,
          itemId: -1,
        });
      }
    } else {
      basket.goBack();
      const backPage = basket.currentPage();
      setNavigationState({
        gen: gen + 1,
        formId: backPage,
        asset: null,
        itemId: -1,
      });
    }
    // Sauvegarde panier
    await saveBasket(basket);
    // Raffraîchit panier.
    await reloadBasket(basket);
  };

  const triggerEvalScript = useCallback(async (
    type,
    triggeringAsset,
    triggeringFormId,
    triggeringItemId,
    apiExtras,
  ) => {
    apiExtras = apiExtras || {};

    if (evaluatingScript.current) {
      return;
    }
    evaluatingScript.current = true;
    //if( type === 'pre' && !(formState && formState.firstTime)) return;
    const { asset, formId, itemId } = navigationState;
    console.log(
      `Navigate to formID #${formId} / asset ${asset} / item ${itemId}`
    );
    const formDef = product.forms.find((f) => f.id === formId);

    const shouldInterrupt = () => {
      return (
        type !== "post" &&
        !(
          navigationState.asset === triggeringAsset &&
          navigationState.formId === triggeringFormId &&
          navigationState.itemId === triggeringItemId
        )
      );
    };
    if (!formDef) return;
    const scripts = formDef.codes.filter((s) => s.event === type);
    let isInterrupted = false;
    let interrupt = () => (isInterrupted = true);
    const api = getApi({
      registry: formState ? formState.registry : null,
      setAdvice,
      getAdvice,
      product,
      basket,
      itemId,
      asset,
      goTo,
      interrupt,
      setAdvices,
      advices,
      needExternalToken,
      redirectUrl,
      externalToken,
      extraConfig,
      currentAppBrand
    });

    for (let i = 0; i < scripts.length; i++) {
      if (!scripts[i].code) continue;
      await evalScript(
        scripts[i].description,
        scripts[i].code,
        { ...api, ...apiExtras },
        shouldInterrupt
      );
      if (isInterrupted) break;
    }
    evaluatingScript.current = false;
  }, [navigationState,
    product,
    formState,
    setAdvice,
    getAdvice,
    basket,
    goTo,
    setAdvices,
    advices,
    needExternalToken,
    redirectUrl,
    externalToken,
    extraConfig,
    currentAppBrand
  ]);

  // Déclenchement rendu formulaire...
  useEffect(() => {
    if (!(navigationState && product)) return;

    if (basket.isComplete()) {
      //navigate('/quote/'+basket.quoteId);
      localStorage.removeItem(basket.product + "_bId");
      return;
    }

    basket.setCallback(() => { });

    let { formId, itemId } = navigationState;
    let redirect = false;

    if (formId === -1) {
      formId = basket.currentPage();
      redirect = true;
    }

    // Formulaire par défaut
    if (product.firstForm && formId === -1) {
      formId = product.firstForm.id;
      redirect = true;
    }

    // Définition du formulaire
    const formDef = product.forms.find((form) => form.id === formId);

    if (!formDef) {
      // Le formulaire n'existe pas.
      navigate("/404");
      return;
    }

    // Pas d'item sur cette page.
    if (!formDef.asset && itemId > -1) {
      itemId = -1;
      redirect = true;
    }

    const assetDef = formDef.asset
      ? product.fleet.find((fleet) => fleet.asset.id === formDef.asset.id)
        ?.asset
      : null;

    if (assetDef) {
      const items = basket.getItems(assetDef.code);

      if (itemId === -1) {
        if (!items.length) {
          basket.addItem(assetDef.code, {});
          redirect = true;
          itemId = 0;
        } else {
          itemId = items.length - 1;
          redirect = true;
        }
      } else {
        for (let i = items.length; i <= itemId; i++) {
          basket.addItem(assetDef.code, {});
        }
      }
    }

    if (redirect) {
      setNavigationState({
        gen: navigationState.gen,
        prev: navigationState.prev,
        asset: assetDef?.code || null,
        itemId,
        formId,
      });
      return;
    }

    const currentKey = [formId, assetDef?.code || "*", itemId || "*"].join("/");

    console.log(
      "%c >> Render (" + navigationState.gen + ") " + currentKey + ".",
      "background: #F00; color: #fff"
    );
    if (currentNav.current === currentKey) {
      return;
    }
    currentNav.current = currentKey;

    const scope = assetDef ? basket.getItem(assetDef.code, itemId) : basket;
    if (scope.currentPage() !== formId) {
      scope.goTo(formId);
    }

    // Initialise le panier et les choix manquants
    product.choices.forEach((choice) => {
      let choiceScopes = choice.asset
        ? basket.getItems(choice.asset.code)
        : [basket];
      choiceScopes.forEach((choiceScope) => choiceScope.initChoice(choice));
    });

    console.log(
      "%c>> Trigger form state [" + formDef.id + "].\n",
      "background: #EFE; color: #050"
    );
    pauseEvent();

    triggerEvalScript("before",
      assetDef?.code || null,
      formId,
      itemId).then(
        () => {
          firstTime.current = true;
          setLoading(false);
          setLoadingNext(false);
          setAdvices(formDef.advices.map((a) => ({ ...a, visible: true })));
          changeFormState({
            form: formDef.code,
            formId: formDef.id,
            advice: formDef.advice,
            nextForm: formDef.nextForm,
            components: formDef.components.map((c, i) => ({
              ...c,
              id: formId + "#" + i,
            })),
            registry: new Map(),
          });
        }
      );
  }, [navigationState]);

  // Déclenchement post Rendu...
  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = false;

    (async () => {
      const { asset, formId, itemId } = navigationState;
      const formDef = product.forms.find((f) => f.id === formId);
      const _formId = getCurrentFormId();

      basket.setCallback(() => refreshForm());
      formDef &&
        console.log(
          "%c>> Render " + formDef.title + ".\n",
          "background: #EFE; color: #050"
        );

      await triggerEvalScript("ready", asset, formId, itemId);

      if (_formId !== getCurrentFormId()) {
        console.log(
          "%c>> Stop render, navigation has changed [" +
          _formId +
          ">" +
          formState.formId +
          "].\n",
          "background: #EFE; color: #050"
        );
        return;
      }

      await resumeEvent();
      // Restaure les valeurs dans le formulaire
      for (let [key, elem] of formState.registry) {
        if (elem.hasOwnProperty("value")) {
          const { asset, itemId } = navigationState;
          const scope = asset ? basket.getItem(asset, itemId) : basket;
          if (scope.has(key)) {
            elem.value = scope.get(key);
          }
        }
        if (elem.hasOwnProperty("checked")) {
          const { asset, itemId } = navigationState;
          const scope = asset ? basket.getItem(asset, itemId) : basket;
          if (scope.has(key)) {
            elem.checked = scope.get(key);
          }
        }
      }

      refreshForm();
    })().catch();
  }, [basket, formState, getCurrentFormId, navigationState, product, refreshForm, triggerEvalScript]);

  if (!formState)
    return (
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "300px" }}
      >
        <CircularProgress />
      </Stack>
    );

  if (!brand) return <Typography>Impossible d'identifier le theme.</Typography>;

  if (loading)
    return (
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "300px" }}
      >
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack className={"QuoteForm"} direction="column" justifyContent="center">
      {canDelete && (
        <Button
          className={"Layout__DeleteButton"}
          variant={"outlined"}
          color={"error"}
          sx={{ position: "absolute", right: 0, top: 0 }}
          startIcon={
            <Icon sx={{ marginTop: "-8px" }}>
              <DeleteOutlineIcon />
            </Icon>
          }
          onClick={handleDelete}
        >
          Réinitialiser
        </Button>
      )}
      {/* <StepNavBar /> */}
      <Box sx={{ marginTop: 2 }}></Box>
      <HumanAdvice
        advice={formState.advice}
        adviser={product.adviser}
        adviserImage={product.adviserImage}
        adviserDescription={product.adviserDescription}
      />

      <Stack
        direction="column"
        justifyContent="stretch"
        sx={{ width: "780px", gap: "15px" }}
      >
        {formState.components
          .reduce((prev, current) => {
            if (current.sameRow && prev.length) {
              prev[prev.length - 1].push(current);
            } else {
              prev.push([current]);
            }
            return prev;
          }, [])
          .map((components, groupId) =>
            components.length === 1 ? (
              <DynamicComponent
                key={navigationState.gen + "-" + components[0].id}
                registry={formState.registry}
                component={components[0]}
              />
            ) : (
              <Stack key={groupId} direction={"row"} gap={1}>
                {components.map((component) => (
                  <DynamicComponent
                    key={navigationState.gen + "-" + component.id}
                    registry={formState.registry}
                    component={component}
                  />
                ))}
              </Stack>
            )
          )}
        {formMessage && (
          <Text key={formMessage} style={"alert"} text={formMessage}></Text>
        )}
      </Stack>

      <Stack direction="row" justifyContent="center" gap={2}>
        {canGoBack && (
          <Button
            className={"Layout__BackButton"}
            variant={"outlined"}
            color={"secondary"}
            startIcon={
              <Icon sx={{ marginTop: "-8px" }}>
                <img src={BackIcon} alt="Précédent" />
              </Icon>
            }
            onClick={handlePrev}
          >
            Précédent
          </Button>
        )}

        {(formState.nextForm || product.subscriptionEnabled) && (
          <Box style={{ m: 1, position: "relative" }}>
            <Button variant="contained" onClick={handleNext} color={"info"}>
              {loadingNext
                ? ""
                : formState.nextForm == null
                  ? "Souscrire"
                  : "Suivant"}
            </Button>
            {loadingNext && (
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default RaterForm;
