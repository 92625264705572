import React from "react";
import "./Advisor.scss";

const AdvisorContent = ({ name, description, image }) => (
  <>
    <img className="Advisor__img" src={image.src} alt={image.alt} />
    <div className="Advisor__span">
      <p className="Advisor__name">{name}</p>
      <p className="Advisor__description">{description}</p>
    </div>
  </>
);

const Advisor = ({ adviser, adviserDescription, adviserImage }) => (
  <div className="Advisor__container">
    <AdvisorContent
      name={adviser}
      description={adviserDescription}
      image={{
        src: adviserImage?.url,
        alt: `${adviser} advisor`,
      }}
    />
  </div>
);

export default Advisor;
