// This store is used to store the external token and the extra config of the application.
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import CryptoJS from "crypto-js";

const initialState = {
    needExternalToken: false,
    redirectUrl: '',
    externalToken: '',
    extraConfig: {},
    currentAppBrand: '',
}

const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
};

const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, 'secret-key');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const remoteAccessStore = create()(
    persist(
        (set) => ({
            ...initialState,
            setExternalToken: (token) => set({ externalToken: token }),
            setNeedExternalToken: (need) => set({ needExternalToken: need }),
            setRedirectUrl: (url) => set({ redirectUrl: url }),
            setCurrentAppBrand: (brand) => set({ currentAppBrand: brand }),
            setExtraConfig: (config) => set({ extraConfig: config }),
            reset: () => set(initialState),
        }),
        {
            name: 'remote-access',
            storage: createJSONStorage(() => localStorage),
            serialize: (state) => encrypt(state),
            deserialize: (state) => decrypt(state),
        }
    )
);