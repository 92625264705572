import React from "react";
import "./Footer.scss";

function footer(props) {
  const { brand } = props;
  return (
    <section className="footer">
      <h3>{brand.title}</h3>
      {(brand.footer || "").split("\n").map((footer, i) => (
        <p key={i}>{footer}</p>
      ))}
    </section>
  );
}

export default footer;
