import { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BatchSubscriptions from "./features/BatchSubscriptions";
import Login from "./features/Login/Login";
import ErrorPage from "./features/Navigation/errorPage";
import { tokenValidate } from "./core/services/TokenService";
import { ThemeProvider } from "@mui/material";
import { LoadingContext } from "./core/utils/loading";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import NotFoundPage from "./features/Navigation/notFoundPage";
import theme from "./core/theme";
import { Rater, Quote } from "./features/Rater";
import "moment/locale/fr";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GraphicProvider, useGraphic } from "./core/utils/graphic-context";
import { Helmet } from "react-helmet";


const CHECKING = 1,
  CONNECTED = 2,
  DISCONNECTED = 3;

const routes = [
  /* Authentification */
  { path: "/login", guarded: false, element: <Login /> },
  { path: "/", guarded: DISCONNECTED, element: <Login /> },
  { path: "/rate/:productCode", guarded: false, element: <Rater /> },
  { path: "/quote/:quoteId", guarded: false, element: <Quote /> },
  {
    path: "/failed-login",
    guarded: DISCONNECTED,
    element: <ErrorPage errorMsg="Tentative de connexion échouée !" />,
  },

  /* Souscription PROTECT en Masse */
  {
    path: "/",
    guarded: CONNECTED,
    element: <Navigate to="/batch-subscriptions/batches/list" />,
  },
  {
    path: "/batch-subscriptions/*",
    guarded: CONNECTED,
    element: <BatchSubscriptions />,
  },

  /* 404 */
  { path: "*", guarded: CONNECTED, element: <NotFoundPage /> },
  { path: "*", guarded: DISCONNECTED, element: <NotFoundPage /> },
];

function App() {
  const locale = "fr";
  const [loading, setLoading] = useState(0);
  const [authCheck, setAuthCheck] = useState(CHECKING);
  const startLoading = useCallback(() => setLoading((v) => v + 1), [loading]);
  const endLoading = useCallback(() => setLoading((v) => v - 1), [loading]);

  useEffect(() => {
    startLoading();
    tokenValidate()
      .then((isValid) => {
        setAuthCheck(isValid ? CONNECTED : DISCONNECTED);
        endLoading();
      })
      .catch(() => setAuthCheck(DISCONNECTED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LoadingContext.Provider value={{ startLoading, endLoading }}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={locale}
          >
            <GraphicProvider>
              <HeaderContent loading={loading} />
              <Router>
                <Routes>
                  {routes
                    .filter(
                      (route) => !route.guarded || route.guarded === authCheck
                    )
                    .map((route, i) => (
                      <Route
                        key={i}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                </Routes>
              </Router>
            </GraphicProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </LoadingContext.Provider>
    </ThemeProvider>
  );
}

function HeaderContent(props) {
  const {loading} = props;
  const { title, icon,config } = useGraphic();

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {icon && <link rel="icon" type="image/png" href={icon} />}
        {config && config.css && <link rel="stylesheet" type="text/css" href={config.css} />}
      </Helmet>
      {loading > 0 && (
        <div className="progress-bar">
          <div className="progress-bar-value" />
        </div>
      )}
    </>
  );
}

export default App;
