import { useCallback, useEffect, useRef, useState } from "react";
import { apiPublicPost } from "../../../core/services";
import { reloadBasket } from "./BasketService";


let _paused = false;

let _working = 0;
let _workingCallback = null;

export const useTrackableState = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks
  const changeCounter = useRef(0); // init mutable ref container for callbacks
  const stateTracker = useRef(initialState); // init mutable ref container for callbacks

  const setStateCallback = useCallback((newState) => {
    if (newState === stateTracker.current) return;
    changeCounter.current = changeCounter.current + 1;
    stateTracker.current = newState;
    _working++;
    cbRef.current = () => {
      _working -= changeCounter.current;
      // Peut arriver suite à une exception
      //if(_working < 0) _working = 0;
      changeCounter.current = null;
      if (_working === 0) {
        if (_workingCallback) _workingCallback();
      }
    }; // store current, passed callback in ref
    setState(newState);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current();
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};

export const pauseEvent = () => {
  if (_paused) return; // Already paused.
  _working = 0;
  console.log(
    "%c>> Pause event (" + _working + ").\n",
    "background: #FCE; color: #540"
  );
  _paused = new Set();
};

export const resumeEvent = async () => {
  if (_paused) {
    console.log(
      "%c>> Waiting state change (" + _working + ").\n",
      "background: #FCE; color: #540"
    );

    if (_working > 0) {
      await new Promise((resolve) => {
        _workingCallback = () => {
          _workingCallback = null;
          resolve();
        };
      });
    }

    console.log(
      "%c>> Resume event (" + _working + ").\n",
      "background: #FCE; color: #540"
    );

    if (_paused) {
      for (const e of _paused) {
        await evalScript(e.title, e.code, window.$api, () => { });
        if (!_paused) break;
      }
      _paused = false;
    }
  }
};
export const evalEvent = async (eventMap, event, api) => {
  if (eventMap && eventMap.has(event)) {
    for (const e of eventMap.get(event)) {
      if (_paused) _paused.add(e);
      else
        await evalScript(
          e.title,
          e.code,
          { ...window.$api, ...api },
          () => false
        );
    }
  }
};

export const evalScript = async (title, script, api, shouldInterrupt) => {
  let methods = [];

  api["__error"] = (e) => {
    // On retire les files d'attentes d'évènement.
    //_working = 0;
    console.log("%c>> Clear waiting event.\n", "background: #FCE; color: #540");

    if (e.stack) {
      const err = (e.stack + "")
        .split("\n")
        .find((l) => /\s+at evalScript/.test(l));
      if (err) {
        const [, row, col] = /([0-9]+):([0-9]+)\)$/.exec(err);
        console.warn(
          e.message +
          ' : \n Script "' +
          title +
          '"\n' +
          script.split("\n")[row - 3]
        );
      } else {
        console.warn(e.message + ' : \n Script "' + title + '"\n' + script);
      }
      console.error(e);
      console.info(
        "Méthodes possibles: \n" +
        methods.filter((m) => m !== "__error").join(", ")
      );
    } else {
      console.warn(script);
      console.error(e);
    }
  };

  api["__shouldInterrupt"] = shouldInterrupt;

  methods = Object.keys(api);

  const asyncScript =
    "return (async (" +
    methods.join(",") +
    ") => { if(__shouldInterrupt()) return;" +
    script +
    "})(" +
    methods.join(",") +
    ").catch(e => __error(e))";

  const f = new Function(...methods, asyncScript);
  console.log(
    "%c>> Execute " + title + ":\n" + script.trim(),
    "background: #EEF; color: #00F"
  );
  return (async () => {
    return f(...methods.map((m) => api[m]));
  })();
};

export const getApi = (properties) => {
  const {
    setAdvice,
    getAdvice,
    registry,
    product,
    basket,
    itemId,
    goTo,
    interrupt,
    asset,
    setAdvices,
    needExternalToken,
    redirectUrl,
    externalToken,
    extraConfig,
    currentAppBrand
  } = properties;

  window.$api = {
    ...basket,
    basket,
    script: async (name, args) => {
      return await apiPublicPost("/scripts/" + name + "/execute", args);
    },
    reloadBasket: async () => {
      return reloadBasket(basket);
    },
    setAdvice,
    getAdvice,
    setAdviceVisible: (advice, visible) => {
      if (typeof visible === "undefined") {
        visible = true;
      }
      setAdvices((prev) => {
        return prev.map((a) => {
          if (a.code === advice) {
            a.visible = visible;
          }
          return a;
        });
      });
    },
    registry,
    currentItemId: itemId,
    go: (formCode) => {
      interrupt();
      const formDef = product.forms.find((f) => f.code === formCode);
      if (!formDef)
        throw new Error(
          "Aucun formulaire '" +
          formCode +
          "' n'existe pour le produit '" +
          product.title +
          "'"
        );
      if (formDef.asset) {
        const assetDef = product.fleet.find((a) => a.code === asset);
        if (!assetDef || formDef.asset.id !== assetDef.id) {
          goTo(formDef.id, -1);
        } else {
          goTo(formDef.id, itemId);
        }
      } else {
        goTo(formDef.id, -1);
      }
    },
    getCurrentItem: () => {
      return basket.getItem(asset, itemId);
    },
    getComponent: (k) => {
      if (!registry)
        throw new Error("Impossible de lire un composant dans un evenement 'before'");
      return registry.get(k);
    },
    getExternalToken: () => externalToken,
    getCurrentAppBrand: () => currentAppBrand,
    getExtraConfig: () => extraConfig,
    getNeedExternalToken: () => needExternalToken,
    getRedirectUrl: () => redirectUrl,
  };
  return window.$api;
};
