import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadBasketById } from "../../service/BasketService";
import { getProductById } from "../../service/ProductService";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { getQuote, getQuoteSteps } from "../../service/QuoteService";
import "./Quote.scss";
import HumanAdvice from "../HumanAdvice/HumanAdvice";
import SubscriptionStep from "../SubscriptionSteps/components/SubscriptionStep/SubscriptionStep";
import { Button } from "@mui/material";
import TagBlock from "../../../../core/blocks/TagBlock/TagBlock";
import subscriptionStepStore from "../../../../core/store/subscription-step-store";
import { useMutation } from "@tanstack/react-query";
import { deleteQuoteFn } from "../SubscriptionSteps/api";

const quoteStatus = {
  new: "Nouveau",
  draft: "En cours",
  completed: "Police",
  error: "Annulée",
};

const Quote = () => {
  const [setQuoteId, quote, setQuote, setSteps] = subscriptionStepStore(
    (state) => [state.setQuoteId, state.quote, state.setQuote, state.setSteps]
  );
  const [product, setProduct] = useState(null);
  const [brand, setBrand] = useState(null);
  const { quoteId = "" } = useParams();
  const [sfExternalRef, setSfExternalRef] = useState("");

  const deleteQuoteMutation = useMutation({
    mutationFn: deleteQuoteFn,
    onSuccess: () => {
      // @ts-ignore
      window.location = `${window.location.origin}/rate/${product.code}?idDevis=${sfExternalRef}`;
    },
  });

  const goBasket = async () => {
    const basket = await loadBasketById(quote.basket);
    setSfExternalRef(basket.externalRef);
    deleteQuoteMutation.mutate({ quoteId: quoteId });
  };

  const fetchData = async () => {
    const quote = await getQuote(quoteId);
    setQuote(quote);
    const pr = await getProductById(quote.product);
    setProduct(pr.config);
    let _brand = pr.config.brands.find(
      (b) => b.url.indexOf(window.location.hostname) >= 0
    );
    // b) variable environnement
    if (!_brand && process.env.REACT_APP_BRAND) {
      _brand = pr.config.brands.find(
        (b) => b.theme === process.env.REACT_APP_BRAND
      );
    }
    if (!_brand) return;
    setBrand(_brand);
    setSteps([]);
    const processedQuote = await getQuoteSteps(quoteId);
    setQuote(processedQuote);
    setSteps(processedQuote.steps);
  };

  useEffect(() => {
    fetchData().catch();
    setQuoteId(quoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  if (!brand) {
    return <></>;
  }

  return (
    <div className="Quote_Layout">
      <div className="Quote_Layout__header">
        <Header product={product} brand={brand} />
      </div>
      <div className="Quote_Layout__center">
        <div className="Quote_Layout__content">
          <div className={"Quote_Layout__section_title"}>
            <h1>
              {
                // @ts-ignore
                product.title
              }
            </h1>
            <div>Proposition n° {quote.veosContract.num}</div>
            <TagBlock
              variant={"active"}
              name={quote ? quoteStatus[quote.status] : "Chargement en cours"}
            />
          </div>
          <div className={"Quote_Layout__buttons"}>
            <Button
              color={"primary"}
              variant={"contained"}
              size={"small"}
              onClick={() => goBasket()}
              disabled={quote.lastMvt === "AN"}
            >
              Modifier
            </Button>
          </div>
          <section>
            <HumanAdvice
              // @ts-ignore
              advice={product.subscriptionAdvice}
              // @ts-ignore
              adviser={product.adviser}
              // @ts-ignore
              adviserImage={product.adviserImage}
              // @ts-ignore
              adviserDescription={product.adviserDescription}
              brand={product.brands[0]}
            />
          </section>
          <section>
            <SubscriptionStep />
          </section>
        </div>
        <aside
          className="Quote_Layout__sidebar"
          style={{
            // @ts-ignore
            backgroundImage: "url('" + product.backgroundImage.url + "')",
          }}
        >
          &nbsp;
        </aside>
      </div>
      <div className="Quote_Layout__footer">
        <Footer brand={brand} />
      </div>
    </div>
  );
};

export default Quote;
