import React, { useState, useRef } from 'react'
import { useTrackableState } from "../service/PageService";
import './Title.scss'

const Title = ({ registry, events, styles, ...component }) => {
    const [title, setTitle] = useState(component.title || "");
    const [subtitle, setSubtitle] = useState(component.subtitle || "");
    const [additionalText, setAdditionalText] = useState(component.additionalText || "");
    const [visible, setVisible] = useTrackableState(true);
    const ref = useRef(null);

    if (component.code) {
        const api = {};
        Object.defineProperties(api, {
            'title': {
                get: () => title,
                set: (v) => setTitle(v)
            },
            'subtitle': {
                get: () => subtitle,
                set: (v) => setSubtitle(v)
            },
            'additionalText': {
                get: () => additionalText,
                set: (v) => setAdditionalText(v)
            },

            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
        });
        registry.set(component.code, api);
    }

    if (!visible) return <></>
    return <div ref={ref} className="TitleBlock" style={styles}>
        <div className="TitleBlock__container">
            <h1 className="TitleBlock__title">{title}
                {additionalText &&
                    <span className="TitleBlock__additionalText">{" "}{additionalText}</span>
                }
            </h1>
            <p className="TitleBlock__text">{subtitle}</p>
        </div>
    </div>
}

export default Title;
