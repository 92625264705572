import React, { useEffect, useState, useContext } from "react";
import "./Login.scss";
import logo from "../../assets/images/logo-carene.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { login } from "../../core/services/TokenService";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../core/utils/loading";

const Login = () => {
  const { startLoading, endLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setDisable(!(user && password));
  }, [user, password]);

  const submit = async () => {
    startLoading();
    await login({ username: user, password: password })
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.setItem("token", res.token);
        navigate("/");
        window.location.reload();
      })
      .catch(() => {
        navigate("/failed-login");
      })
      .finally(() => {
        endLoading();
      });
  };
  return (
    <div className="Login">
      <div className="Login__Block">
        <div className="Login__Block__Logo">
          <img src={logo} alt="logo carene" />
        </div>
        <div className="Login__Form">
          <div className="Login__Form__User">
            <Typography variant="h6">Identifiant</Typography>
            <div className="Login__Form__User__Input">
              <OutlinedInput
                id="identifiant"
                type="text"
                placeholder="j.celere@lesbonscourtiers.com"
                size="small"
                onChange={(e) => setUser(e.target.value)}
                sx={{
                  width: "100%",
                  bgcolor: "white",
                  position: "flex",
                  flex: 1,
                }}
              />
            </div>
          </div>

          <div className="Login__Form__Password">
            <Typography variant="h6">Mot de passe</Typography>
            <div className="Login__Form__Password__Input">
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                sx={{ width: "100%", position: "flex", flex: 1 }}
                placeholder="........"
                size="small"
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>

          <div className="Login__Form__Button">
            <Button
              disabled={disable}
              onClick={() => submit()}
              color="primary"
              variant="contained"
            >
              Connexion
            </Button>
          </div>
        </div>
      </div>
      <div className="Login__Image" />
    </div>
  );
};

export default Login;
