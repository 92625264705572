import React from "react";
import "./HumanAdvice.scss";
import { Stack } from "@mui/material";
import ReactMarkdown from "react-markdown";
import Advisor from "../Advisor/Advisor";
import { useGraphic } from "../../../../core/utils/graphic-context";

const HumanAdvice = (props) => {
  const { config } = useGraphic();

  if (config.overrideProperties?.HumanAdvice) {
    props = { ...props, ...config.overrideProperties.HumanAdvice };
  }

  const { advice, adviser, adviserImage, adviserDescription } = props;

  return (
    <div className="HumanAdvice">
      <Stack direction={"row"} alignItems={"start"} justifyContent={"center"}>
        <Advisor
          adviser={adviser}
          adviserImage={adviserImage}
          adviserDescription={adviserDescription}
        />
        <div className="HumanAdvice__Bubble">
          <div className="HumanAdvice__Bubble-triangle"></div>
          <ReactMarkdown>{advice}</ReactMarkdown>
        </div>
      </Stack>
    </div>
  );
};

export default HumanAdvice;
