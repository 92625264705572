import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBasket, getBasketByExternalRef } from "../../service/BasketService";
import { getProductConfig } from "../../service/ProductService";
import { BasketContext } from "../../context";
import Header from "../Header/Header";
import RaterForm from "../RaterForm/RaterForm";
import AdviceCard from "../AdviceCard/AdviceCard";
import Footer from "../Footer/Footer";
import "./Rater.scss";
import { getQuote } from "../../service/QuoteService";
import { useGraphic } from "../../../../core/utils/graphic-context";
import { remoteAccessStore } from "../../store/remote-access";


const Rater = () => {
  const { productCode } = useParams();

  const location = useLocation();

  const [basket, setBasket] = useState(null);
  const [product, setProduct] = useState(null);
  const [brand, setBrand] = useState(null);
  const [navigationState, setNavigationState] = useState(null);
  const [formState, setFormState] = useState(null);
  const [advices, setAdvices] = useState([]);
  const currentForm = useRef();
  const navigate = useNavigate();
  const { setTitle, setTheme, setConfig, setIcon, setLogo } = useGraphic();

  const [needExternalToken,
    redirectUrl,
    setNeedExternalToken,
    setExternalToken,
    setCurrentAppBrand,
    setExtraConfig,
    setRedirectUrl] = remoteAccessStore((state) => [
      state.needExternalToken,
      state.redirectUrl,
      state.setNeedExternalToken,
      state.setExternalToken,
      state.setCurrentAppBrand,
      state.setExtraConfig,
      state.setRedirectUrl,
    ]);

  useEffect(() => {
    if (needExternalToken) {
      // Extract token from URL if needed
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      if (token) {
        setExternalToken(token);
      } else {
        window.location.href = redirectUrl;
      }
    }
  }, [location.search, needExternalToken, redirectUrl, setExternalToken]);


  const goTo = (targetFormId, targetItemId) => {
    const formDef = product.forms.find((f) => f.id === targetFormId);
    const assetDef = formDef?.asset
      ? product.fleet.find((f) => f.asset.id === formDef.asset.id)?.asset
      : null;

    if (navigationState) {
      const { asset, itemId } = navigationState;
      if (targetItemId === -1 && asset === assetDef?.code) {
        targetItemId = itemId;
      }
    }

    setNavigationState({
      gen: navigationState ? navigationState.gen + 1 : 1,
      formId: targetFormId,
      asset: assetDef?.code || null,
      itemId: targetItemId,
    });
  };

  const changeFormState = (newState) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }));
    currentForm.current = newState.formId || formState.formId;
  };

  const getCurrentFormId = () => currentForm.current;

  const context = {
    basket,
    setBasket,
    formState,
    changeFormState,
    setNavigationState,
    product,
    goTo,
    navigationState,
    getCurrentFormId,
    brand,
    setAdvices,
    advices,
  };

  useEffect(() => {
    async function fetchBasket() {
      const urlParams = new URLSearchParams(window.location.search);

      let basket,
        idDevis = null;

      if (urlParams.has("idDevis")) {
        idDevis = urlParams.get("idDevis");
        try {
          basket = await getBasketByExternalRef(idDevis);
        } catch (e) {
          basket = null;
          if (!urlParams.has("origin")) {
            localStorage.removeItem(`${productCode}_bId`);
          }
        }
      }

      if (!basket) {
        basket = await getBasket(productCode, idDevis || null);
      }
      if (basket.isComplete()) {
        if (basket.quoteId) {
          const quote = await getQuote(basket.quoteId);
          if (basket.id === quote.basket) {
            navigate("/quote/" + quote._id);
            return;
          } else {
            basket.quoteId = null;
            basket.goBegin();
          }
        }
      }
      setBasket(basket);
      setProduct(await getProductConfig(basket.product));
    }
    fetchBasket();
    return () => {
      setBasket(null);
      setProduct(null);
    };

  }, [productCode, navigate]);

  useEffect(() => {
    if (product) {
      // Extract information from the product
      let _brand = product.brands.find(
        (b) => b.url && b.url.includes(window.location.hostname)
      );
      if (!_brand && process.env.REACT_APP_BRAND) {
        _brand = product.brands.find(
          (b) => b.theme === process.env.REACT_APP_BRAND
        );
      }
      if (!_brand) return;

      setBrand(_brand);


      // Legacy code
      _brand.title && setTitle(_brand.title);
      _brand.theme && setTheme(_brand.theme);
      _brand.theme && setCurrentAppBrand(_brand.theme);
      _brand.icon?.url && setIcon(_brand.icon.url);
      _brand.logo && setLogo(_brand.logo);
      // Extract data from extra-config
      _brand.config && setConfig(_brand.config);
      _brand.config && setConfig(_brand.config);
      _brand.config && _brand.config.extraConfig && setExtraConfig(_brand.config.extraConfig);
      _brand.needExternalToken && setNeedExternalToken(_brand.needExternalToken === true);
      _brand.redirectUrl && setRedirectUrl(_brand.redirectUrl);

      goTo(-1, -1);
    }
  }, [product]);

  if (!brand) return <></>;

  return (
    <BasketContext.Provider value={context}>
      <div className="Rater_Layout">
        <div className="Rater_Layout__header">
          <Header product={product} />
        </div>
        <div className="Rater_Layout__center">
          <div className="Rater_Layout__content">
            <RaterForm />
          </div>
          <RaterAside
            product={product}
            advices={advices}
            formState={formState}
          />
        </div>
        <div className="Rater_Layout__footer">
          <Footer brand={brand} />
        </div>
      </div>
    </BasketContext.Provider>
  );
};

/**
 * Extract the RaterSide component to override it
 * @param {*} props
 * @returns
 */
const RaterAside = (props) => {
  const { config } = useGraphic();

  if (config.overrideProperties?.RaterAside) {
    props = { ...props, ...config.overrideProperties.RaterAside };
  }
  const { product, advices, formState } = props;
  return (
    <aside
      className="Rater_Layout__sidebar"
      style={{
        backgroundImage: `url('${product.backgroundImage.url}')`,
      }}
    >
      {advices
        .filter((advice) => advice.visible)
        .map((advice, i) => (
          <AdviceCard key={i} registry={formState?.registry} advice={advice} />
        ))}
    </aside>
  );
};

export default Rater;
